.login-comp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 28px 18px 0px 18px;

  .bg-image {
    width: 100vw;
    height: 168vh;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -99;

    img {
      width: 100vw;
      height: 168vh;
      object-fit: cover;
    }

    @media all and (max-width: 760px) {
      -webkit-filter: brightness(90%); /* Safari 6.0 - 9.0 */
      filter: brightness(90%);
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    .title {
      width: 100%;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      color: #fff;
      font-size: 10vw;
      letter-spacing: 2.8px;
    }

    .sub-title {
      width: 100%;
      font-family: "Noto Sans TC", sans-serif;
      font-weight: 500;
      font-size: 3.8vw;
      color: #fafafa;
      letter-spacing: 15px;
    }
  }

  .login-form {
    @mixin placeholder-color($color) {
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: $color;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: $color;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: $color;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: $color;
      }
    }

    .input-area {
      // padding: 8px;
      margin-top: 28px;
      width: 100%;
      .label {
        width: 100%;
        // padding: 4px 8px;
        color: #fafafa;
        font-size: 15px;
        display: inline-flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        font-family: "Noto Sans TC", sans-serif;
        font-weight: 500;
        letter-spacing: 1px;

        .btn-area {
          display: inline-flex;
          flex-wrap: nowrap;

          justify-content: space-between;
          // padding: 0px 8px;
          p {
            width: 60px;
            color: #1890ff;
            text-align: center;
            // padding: 2px 8px;
            // border: 2px solid red;
            margin-right: 16px;
            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
          }
        }
      }

      .input-field {
        width: 100%;
        padding: 8px 8px;
        font-size: 17px;
        background-color: #e8f0fe;
        color: #000;
        margin-top: 8px;
        margin-bottom: 28px;
        border-radius: 10px;
        .select-notice {
          color: #ff7875;
        }
        .input {
          width: 100%;
          border: none;
          @include placeholder-color(#ff7875);
          color: #000;
          background-color: #e8f0fe;
          line-height: 17px;
          font-size: 17px;
          padding-left: 8px;
        }

        .disable {
          color: #bfbfbf;
        }
      }

      .with-select-btn {
        display: inline-flex;
        flex-wrap: nowrap;

        .btn {
          width: 90px;
          height: 30px;
          margin-right: 8px;
          background-color: #1890ff;
          box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
          border-radius: 4px;
          display: inline-flex;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          color: #fff;
          padding: 8px;
          font-size: 13px;
          p {
            margin-left: 8px;
          }
        }
      }
    }

    .submit-btn-area {
      padding: 18px 0px;
      .am-button-primary {
        background-color: #4c8dff;
        border: #4c8dff;
      }
    }
  }
}
